import logo from './logo.png';
import './App.css';
import { Model, settings } from "survey-core";
import { Survey } from "survey-react-ui";
import { survey_data } from './data';
import "survey-core/defaultV2.min.css";
import Theme from "./theme";
import { useState } from 'react';
import { Converter } from "showdown";
import { ResultChart } from './ResultChart';

settings.titleTags.page = "h1";
settings.maxWidth = "100%";



function App() {
	const [survey] = useState(new Model(survey_data));
	const [result, setResult] = useState(undefined)
	const [showThanksDone, setShowThanksDone] = useState(false)
	const storageItemKey = "my-survey";
	const saveSurveyData = (s) => {
		const data = s.data;
		data.pageNo = s.currentPageNo;
		window.localStorage.setItem(storageItemKey, JSON.stringify(data));
	}

	survey.applyTheme(Theme);
	survey.pageNextText = "Begin Assessment"
	survey.onValueChanged.add(saveSurveyData);

	
	survey.onCurrentPageChanged.add((sender, options) => {
		let n = survey.currentPageNo
		console.log(n)
		if (n > 0){
			if (n < 6)
				survey.pageNextText = "Next: " + survey.getPage(n + 1).navigationTitle + "   >";
			survey.pagePrevText = "Previous: " + survey.getPage(n - 1).navigationTitle + "   <";
		}
		if (n == 0)
			survey.pageNextText = "Begin Assessment"
		window.scrollTo(0, 0)
		saveSurveyData(sender)
	})

	const prevData = window.localStorage.getItem(storageItemKey) || null;
	if (prevData) {
        const data = JSON.parse(prevData);
        survey.data = data;
        if (data.pageNo) {
            survey.currentPageNo = data.pageNo;
        }
    }

	const converter = new Converter();
    survey.onTextMarkdown.add(function (survey, options) {
        // Convert Markdown to HTML
        let str = converter.makeHtml(options.text);
        // Remove root paragraphs <p></p>
        str = str.substring(3);
        str = str.substring(0, str.length - 4);
        // Set HTML markup to render
        options.html = str;
    });

	let even = true;
	
	survey.onUpdateQuestionCssClasses.add(function(_, options) {
		const classes = options.cssClasses;
        if (options.question.getType() != "html") {
			if (even){
				classes.mainRoot += " even";
			}
			even = !even
        }else {
			even = true
		}
    });

	survey.onComplete.add((sender, options) => {
		let data = sender.data
		console.log(data)
        setResult(sender.data)
		if ("Industry sector" in data && data["Industry sector"].includes("other")){
			let i = data["Industry sector"].findIndex(x => x == "other")
			data["Industry sector"][i] = 7//data["Industry sector-Comment"]
			// delete data["Industry sector-Comment"]
		}

		if ("Industry sector" in data) {
			console.log(data["Industry sector"])
			for (let i = 1; i <= 7; i++){
				if (data["Industry sector"].includes(i))
					data["Industry sector - " + i] = 1
				else
					data["Industry sector - " + i] = 0
			}
			delete data["Industry sector"]
		}
		console.log(data)
		// fetch("http://injpuni.it.csiro.au:5000/insert", {
		fetch("/insert",{
		// fetch("/insert_test",{
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data)
		})
		window.scrollTo(0, 0)
		window.localStorage.setItem(storageItemKey, "");
    });

	const showResult = () => {
		return <ResultChart result={result}/>
	}

	const showThanks = () => {
		return <div style={{paddingTop: 100}}>
			<center>
				<h1 style={{paddingBottom: 50}}>Thanks for your participation!</h1>
			
				<button className='sd-btn sd-navigation__next-btn' onClick={() => {setShowThanksDone(true)}}>View your assessment results</button>
			</center>
		</div>
	}
	
	return (
		<div className="App">
			<div className='container'>
				<div className='header' >
					<img className='logo' src={logo}/>
					<div className='header-title' style={{display: "inline"}}>
						<h1 className='header-title-h1'>Digital maturity assessment for traceability adoption and implementation</h1>
						{/* <h5 className='subtitle'>Understanding the current level of digital traceability adoption and readiness across Australian agriculture</h5> */}
					</div>
				</div>
				{
					result === undefined ?
						<Survey model={survey} />
					:
						showThanksDone ?
							showResult()
						:
							showThanks()
				}
				
			</div>
		</div>
	);
}

export default App;
