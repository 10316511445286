import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar, Radar } from 'react-chartjs-2';
import { feedback } from './feedback';
import './ResultChart.css'
import { useEffect, useState } from 'react';
ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Title, Tooltip, Legend);
ChartJS.defaults.color = "black";

export const ResultChart = ({result}) => {
    const options = {
        responsive: true,
        elements: {
            line: {
                borderWidth: 3
            }
        },
        plugins: {
            legend: {
                display: false,
            },
        }, 
        scales: {
            
            r: {
                ticks: {
                    stepSize: 1
                },
                beginAtZero: true,
                max: 4,
                min: 0,
                pointLabels: {
                    font: {
                        size: 16
                    }
                }
            }
        } 
        // scales: {
        //     y: {
        //         min: 0,
        //         max: 5,
        //         title:{
        //             display: true,
        //             text: "Digital maturity level",
        //             font: {
        //                 size: 20,
        //                 weight: 'bold'
        //             }
        //         }
        //     },
        //     x: {
        //         title:{
        //             display: true,
        //             text: "Survey section",
        //             font: {
        //                 size: 20,
        //                 weight: 'bold'
        //             }
        //         }
        //     }
        // }
    };
    const labels = ["Value & strategy", "Technology", "Data collection", "Capability", "Data sharing"]
    const [data, setData] = useState({
        labels, 
        datasets: [{
            data: [0,0,0,0,0],
            backgroundColor: "#ffffffff",
            borderColor: "#ff33ff99",
            borderWidth: 3,
        }]
    })


    const getColor = (v) => {
        if (v < 2)
            return "#fff1f1"
        else if (v < 3)
            return "#fff8e1"
        else if (v < 4)
            return "#edf5ff"
        else 
            return "#defbe6"
    }

    const getBorderColor = (v) => {
        if (v < 2)
            return "#F9D6D8"
        else if (v < 3)
            return "#FCEDBC"
        else if (v < 4)
            return "#A5BFF0"
        else 
            return "#C7EFD3"
    }

    let p = [0,0,0,0,0]
    let n = [7, 7, 6, 8, 5]
    let nn = [7, 7, 6, 8, 5]
    for (let i = 0; i < 5; i ++){
        for (let j = 1; j <= n[i]; j ++) {
            if (("p" + (i + 1) + "-" + j) in result){
                if (result["p" + (i + 1) + "-" + j] != 6)
                    p[i] += result["p" + (i + 1) + "-" + j]
                else if (nn[i] > 1)
                    nn[i] -= 1
            }
        }
        p[i] = (p[i] / nn[i])
        if (p[i] > 4)
            p[i] = 4
        else if (p[i] > 3)
            p[i] = 3
        else if (p[i] > 2)
            p[i] = 2
        else
            p[i] = 1
    }
    useEffect(() => {
        console.log("set data")
        setData({
            labels, 
            datasets: [{
                data: p,
                borderWidth: 3,
                backgroundColor: "#ffffff00",
                borderColor: "#0000ff99",
            }]
        })
    }, [])

    const getTableResult = (v) => {
        let text = ""
        if (v < 2)
            text = "Emerging"
        else if (v < 3)
            text = "Transitional"
        else if (v < 4)
            text = "Competitive"
        else 
            text = "Transformative"
        return <span style={{backgroundColor: getBorderColor(v), borderRadius:15, padding:5}}>
            {text}
        </span>
    }

    const getDescription = (title , v) => {
        if (v < 2)
            return feedback[title][0]
        else if (v < 3)
            return feedback[title][1]
        else if (v < 4)
            return feedback[title][2]
        else
            return feedback[title][3]
    }       

    const getDetailedResults = () => {
        return <div style={{pageBreakAfter: "always"}}>
            <h2 style={{fontSize: "var(--sjs-article-font-x-large-fontSize, 28px)", lineHeight: "var(--sjs-article-font-x-large-lineHeight, 36px)"}}>Your detailed results</h2>
            <table>
                <tr style={{background: "#E0E0E0"}}>
                    <th style={{width:"20%"}}>Survey section</th>
                    <th style={{width:"20%"}}>Maturity level</th>
                    <th>Maturity level explanation</th>
                </tr>
                {["Value & strategy", "Technology", "Data collection", "Capability", "Data sharing"].map((v, i) => {

                    return <tr style={i % 2 == 1 ? {background: "#F4F4F4", borderTop: "1px solid #E0E0E0"} : {}}>
                        <td style={i % 2 == 1 ? {borderTop: "1px solid #E0E0E0", borderBottom: "1px solid #E0E0E0"} : {}}>{v}</td>
                        <td style={i % 2 == 1 ? {borderTop: "1px solid #E0E0E0", borderBottom: "1px solid #E0E0E0"} : {}}>{getTableResult(p[i])}</td>
                        <td style={i % 2 == 1 ? {borderTop: "1px solid #E0E0E0", borderBottom: "1px solid #E0E0E0"} : {}}>{getDescription(v, p[i])}</td>
                    </tr>
                })}
            </table>
        </div>
    }

    return <div>
        <h1 style={{fontSize: "var(--sjs-article-font-xx-large-fontSize, 42px)", lineHeight: "var(--sjs-article-font-xx-large-lineHeight, 50px)"}}>Your digital traceability maturity assessment results</h1>
        <p>Please note: The following results of your digital maturity are assessed against standard levels of digital maturity. The nature of your business and operations is not considered in this assessment. Hence, when interpreting these results and developing action plans, you should consider the nature of your business. For example, the need for digital technologies in intensive farming sectors can be very different from broadacre cropping and livestock farming sectors.</p>
        <div style={{display: "flex", flexWrap: "wrap", marginTop:10, marginBottom: 10, pageBreakAfter: "always"}}>
            <div style={{minWidth: 360, width:"400px", maxWidth: "80%",  margin: "0 auto"}}>
                <Radar options={options} data={data} aria-label='A chart that shows the results of your digital maturity.'/>
            </div>
            <div style={{width: "250px", paddingTop: 20}}>
                <div style={{border: "1px solid grey", padding: 10, margin: 10}}>
                    <div style={{textAlign: "left"}}>
                    <b>Legend</b><br/>
                        <p>1 = Emerging</p>
                        <p>2 = Transitional</p>
                        <p>3 = Competitive</p>
                        <p>4 = Transformative</p>
                    </div>
                </div>
            </div>
        </div>
        {getDetailedResults()}
        <h2 style={{fontSize: "var(--sjs-article-font-x-large-fontSize, 28px)", lineHeight: "var(--sjs-article-font-x-large-lineHeight, 36px)"}}>Resources for helping you implement digital traceability</h2>
        {/* <p style={{fontSize:"1.5rem"}}>Traceability information</p> */}
        <ul>
            <li><b>Quick Start Guide:</b> Get started with traceability by consulting the quick start guide, including a cost-benefit-analysis calculator and tips on managing data sharing. Please visit <a target="_blank" href="https://agriculture.vic.gov.au/export/traceability/quick-start-guide">here</a></li>
            <li><b>Why traceability:</b> Learn more about traceability and its benefits <a target="_blank" href="https://agriculture.vic.gov.au/export/traceability/what-is-traceability">here</a></li>
            <li><b>Case studies:</b> Watch other producers’ experiences with digital traceability in the beef, lamb, honey, stone fruit, and avocado sectors <a target="_blank" href="https://agriculture.vic.gov.au/export/traceability/a-taste-you-can-trace">here</a></li>
            <li><b>Podcasts on traceability:</b>  Hear from farmers, researchers and other stakeholders discussing about traceability and how it can connect businesses along the supply chain <a target="_blank" href="https://agriculture.vic.gov.au/support-and-resources/podcasts/agvic-talk-podcast-series/agvic-talk-season-6">here</a></li>
            <li><b>Available projects, programs and resources:</b> Find out more about the wide range of traceability projects, programs, and resources available <a target="_blank" href="https://agriculture.vic.gov.au/export/traceability/resources-and-support">here</a></li>
        </ul>
        <div className='sd-action-bar sd-footer sd-body__navigation sd-clearfix' style={{float: "right"}}>
            <div className='sv-action'>
                <div className='sv-action__content'>
                    <button className='sd-btn sd-navigation__next-btn' onClick={() => {window.print()}}>Print your assessment results</button>
                </div>
            </div>
        </div>
    </div>
}