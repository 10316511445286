import { FunctionFactory } from "survey-core";

function validatePostcode (params) {
	const value = Number(params[0]);
	console.log(value)
	return value >= 200 && value <= 9999;
}
FunctionFactory.Instance.register("validatePostcode", validatePostcode);

const isRequired = true


const agreeChoices = ["Strongly disagree", "Disagree", "Neither agree nor disagree", "Agree", "Strongly agree"]
const dataChoices = ["None of the data", "A little data", "Some data", "Most data", "All of the data"]
const easeChoices = ["Not easily at all", "A little difficult", "Somewhat easily", "Easily", "Very easily"]
const oftenChoices = ["Hardly ever", "Rarely", "Sometimes", "Often when the opportunity arises", "Always when the opportunity arises"]
const agreeQuestions = (p, q, reverse = false, withNotApplicable = false) => {
	let choices = agreeChoices.map((c, i) => {
		return {
			text: c,
			value: reverse ? 5 - i : 1 + i
		}
	})
	if (withNotApplicable){
		choices.push({
			text: "Not applicable",
			value: 6
		})
	}
	return {
		"name": p,
		"type": "radiogroup",
		"title": q,
		"isRequired": isRequired,
		"colCount": withNotApplicable ? 5 : 5,
		"choices": choices
	}
}

const choicesQuestions = (p, q, choices, withNotApplicable = false) => {
	let cs = choices.map((c, i) => {
		return {
			text: c,
			value: 1 + i
		}
	})
	if (withNotApplicable){
		cs.push({
			text: "Not applicable",
			value: 6
		})
	}
	return {
		"name": p,
		"type": "radiogroup",
		"title": q,
		"isRequired": isRequired,
		"colCount": withNotApplicable ? 5 : 5,
		"choices": cs
	}
}

const description = (t, d) => {
	return {
		type: "html",
		html: "<h1>" + t + "</h1><p style='font-size:1.25em;'><b>" + d + "</b></p>" 
	}
}

const subtitle = (t) => {
	return {
		type: "html",
		html: "<h2>" + t + "</h2>" 
	}
}

const randomOrder = (array) => {
	// for (let i = array.length - 1; i > 0; i--) {
    //     const j = Math.floor(Math.random() * (i + 1));
    //     [array[i], array[j]] = [array[j], array[i]];
    // }
	return array
}


const arrayToValue = (l) => {
	return l.map((t, i) => {
		return {
			value: i + 1,
			text: t
		}
	})
}

export const survey_data = {
    "showProgressBar": "auto",
    "progressBarType": "pages",
    "progressBarShowPageNumbers": true,
    "progressBarShowPageTitles": true,
	"showQuestionNumbers": "off",
    "pages": [{
        "navigationTitle": "Introduction",
		"t":"test",
		"showTitle": false,
        "elements": [{
            type: "html",
			name: "introductionPanel",
			html: "<h2>What is digital traceability?</h2>"+
				"<ul><li>Digital traceability is the ability to follow the movement of a product through all stages of production, processing, and distribution, including export, using digital systems.</li></ul>" + 
				"<h2>Why is this assessment being conducted?</h2>"+
				"<ul><li>This assessment aims to understand producers' views, experience, and concerns in participating in, and/or implementing digital traceability.</li>" + 
				"<li>The assessment will present you with your business’ digital traceability readiness result.</li>" + 
				"<li>Information and a step-by-step guide on digital traceability implementation will be provided at the end.</li>" +
				"<li>The findings from this assessment may inform industry and government agencies in developing targeted strategies and policies to support agribusiness’ adoption of digital traceability.</li></ul>" + 
				"<h2>Who is conducting this assessment?</h2>"+
				"<ul><li>The assessment is developed by CSIRO and co-funded by Commonwealth Department of Agriculture, Fisheries and Forestry (DAFF) and CSIRO.</li></ul>" + 
				"<h2>Participation and withdrawal</h2>"+
				"<ul><li>The assessment is designed for producers to complete, and it will take about 10 minutes to complete.</li>" + 
				"<li>Your participation is completely voluntary. We don’t think there are any risks involved with you taking part in this assessment.</li>" + 
				"<li>No identifiable personal information will be collected.</li>" + 
				"<li>You are free to withdraw at any time by simply closing the browser window, and your data will be deleted. </li></ul>" + 
				"<h2>Privacy and Confidentiality</h2>"+
				"<p>Your personal information is protected by the Privacy Act 1988 (Cth) and CSIRO will handle your personal information in accordance with this Act and the NHMRC National Statement on Ethical Conduct in Human Research (2007, updated 2018). All information collected will be treated confidentially. Your responses will only be seen by members of our research team and will be stored as per CSIRO’s Recordkeeping Procedure. The findings of the aggregated assessment data will be reported to DAFF, published in scientific journal papers, and presented to industry stakeholders and in academic conferences. De-identified, non-sensitive data collected through the assessment may be shared with other researchers for the purposes of verifying published results or advancing other research on this topic. </p>" +
				"<h2>Further information and contacts</h2>"+
				"<p>If you would like further information on this project, please feel free to contact the lead researcher, Dr Airong Zhang via email at <a href='mailto:Airong.Zhang@csiro.au'>Airong.Zhang@csiro.au</a>. This study has been approved by CSIRO’s Social Science Human Research Ethics Committee. Any concerns or complaints about the conduct of this study can be raised with the Manager of Social Responsibility and Ethics by email at <a href='mailto:csshrec@csiro.au'>csshrec@csiro.au</a> or phone on (+617) 3833 5693.</p>" +
				"<p><b><i>If you consent to take part in the assessment, please click the ‘Begin Assessment’ button below to begin.</b></i></p>"
				
        }],
    }, {
		"navigationTitle": "Value & Strategy",
		"elements": [
			description("Section 1: Value and strategy", "The following statements are about what your business thinks about digital traceability. Please indicate the extent to which you agree with the following statements."),
			subtitle("Your business values"),
			randomOrder([
				agreeQuestions("p1-1", "Digital traceability systems can help efficiently manage food safety and food quality."),
				agreeQuestions("p1-2", "Digital traceability systems can help efficiently manage biosecurity."),
				agreeQuestions("p1-3", "Digital traceability systems can give consumers trust in the food products that they purchase."),
				agreeQuestions("p1-4", "Products with digital traceability information have a competitive advantage in markets."),
				agreeQuestions("p1-5", "The traceability data that you collect can inform and enhance efficiency of your business management.", false, true)
			]),
			subtitle("Your digital strategy"),
			randomOrder([
				agreeQuestions("p1-6", "Your business has a clear plan that shows the path and steps for implementing a digital traceability system."),
				agreeQuestions("p1-7", "Your business is taking active measures to collect digital operational data for traceability purposes."),
			]),
			subtitle("Broader industry leadership"),
			randomOrder([
				agreeQuestions("p1-8", "Your industry (e.g., member groups/ peak bodies / associations, Research and Development Corporations (RDCs), state government) actively promotes and demonstrates the value of digital traceability (e.g., supplies information through factsheets and workshops)."),
				agreeQuestions("p1-9", "Your industry communicates best-practice business strategies to implement digital traceability systems (e.g., via demonstrations from technology companies and/or leading businesses)."),
				agreeQuestions("p1-10", "Your industry has established networks and partnerships that provide support to your business on digital traceability matters."),
			])
			
		].flat()
    }, {
		"navigationTitle": "Technology",
		"elements": [
			description("Section 2: Technology you use", "The following questions are about the technology and infrastructure in your business. Please indicate the extent to which you agree with the following statements."),
			subtitle("Your communication infrastructure"),
			randomOrder([agreeQuestions("p2-1", "Your current communication infrastructure (e.g., <span title='LoRaWAN is a low-power,wide area networking protocol built on top of the LoRa radio modulation technique. It wirelessly connects devices to the internet and manages communication between end-node devices and network gateways.'><u>LoRaWAN</u></span>, 5G, satellite, wifi, radio, mobile phones, and apps)  completely meets your needs in relation to coverage and reliability.", false, true),
			agreeQuestions("p2-2", "Your current communication technologies/infrastructure (including mobile phones and apps) have the capacity to handle the volumes of data you need (e.g., sending data online/offline to, or downloading data from the cloud).", false, true),
			subtitle("Your in-business technology")]),
			randomOrder([choicesQuestions("p2-3", "Thinking of the digital technologies in your business, how easy is it to use them?", ["None of the technologies are easy to use", "Most are not easy to use", "Some technologies are easy to use", "Most are easy to use", "All technologies are easy to use"]),
			choicesQuestions("p2-4", "How would you rate the technical support your business receives from technology & service providers in general?", ["Very Poor", "Poor", "Satisfactory", "Good", "Excellent"], true)]),
			subtitle("The technology market place"),
			randomOrder([agreeQuestions("p2-5", "When thinking about purchasing new digital technologies for data collection, you find it hard to find affordable options.", true, true),
			agreeQuestions("p2-6", "Most digital traceability platforms/systems on the market can be easily integrated with what you currently have.", false, true),
			agreeQuestions("p2-7", "It is hard to find new digital technologies to meet your data collection needs for traceability.", true, true)])
		].flat()
    }, {
		"navigationTitle": "Data collection",
		"elements": [
			description("Section 3: Data you collect", "The following questions are about the data you collect in your business. Data can include both internal data (e.g., from sensors, machinery, weather stations, drones) and external data from other businesses and sources (e.g., satellites, forecasts). Please indicate the extent to which you agree with the following statements."),
			subtitle("Data you collect"),
			randomOrder([agreeQuestions("p3-1", "Your business digitally collects all the data that you need to meet traceability requirements.", false, true),
			choicesQuestions("p3-2", "How much of the data that your business collects are stored electronically?", dataChoices)]),
			subtitle("Interoperability"),
			randomOrder([choicesQuestions("p3-3", "Thinking of the datasets collected by your business, how easily can they be integrated into one platform/system?", easeChoices, true),
			agreeQuestions("p3-4", "You interact with your supply chain partners digitally by using system integration platforms such as cloud-based services.", false, true),
			choicesQuestions("p3-5", "When your business is asked to provide electronic data by your supply chain partners for traceability, how often is the data requested in a different format from your own business records?", ["All the time (they request it in different formats)", "Often", "Some of the time", "Rarely", "Never (they request it in the same format)"], true),
			agreeQuestions("p3-6", "Your business has measures in place to ensure the integrity and security of your data (e.g., electronic collection, storage and sharing of data, backing up data, using strong passwords).", true)])
		].flat()
	}, {
		"navigationTitle": "Digital capability",
		"elements": [
			description("Section 4: Your digital capability", "The following questions are about your business’ capability in managing data and digital information systems. Please indicate the extent to which you agree with the following statements."),
			subtitle("Knowledge of digital systems"),
			randomOrder([agreeQuestions("p4-1", "You have a good understanding of current and emerging communication options available to your business (e.g., <span title='LoRaWAN is a low-power,wide area networking protocol built on top of the LoRa radio modulation technique. It wirelessly connects devices to the internet and manages communication between end-node devices and network gateways.'><u>LoRaWAN</u></span>, 5G, satellite, wifi, radio)."),
			agreeQuestions("p4-2", "You have a good understanding of digital traceability."),
			agreeQuestions("p4-3", "You know how to digitally collect the data required by your supply chain partners for traceability.")]),
			subtitle("Skills with digital systems", false, true),
			randomOrder([agreeQuestions("p4-4", "You or your staff are capable of operating the digital technologies used in your business (including digital devices, apps and machinery).", false, true),
			agreeQuestions("p4-5", "You or your staff are capable of maintaining and storing data in a securely available form (such as in the cloud).", false, true),
			agreeQuestions("p4-6", "You or your staff are capable of solving problems that sometimes arise when using digital technologies.", false, true),
			choicesQuestions("p4-7", "How often do you or your staff participate in training courses (e.g., extension events) and/or workshops on how to use digital technologies?", oftenChoices, true),
			agreeQuestions("p4-8", "You know where to seek expert assistance on issues related to digital technologies.")]),
		].flat()
	}, {
		"navigationTitle": "Data sharing",
		"elements": [
			description("Section 5: Your data sharing", "The following questions are about how data is managed and shared with your supply chain partners. Please indicate the extent to which you agree with the following statements."),
			randomOrder([agreeQuestions("p5-1", "When sharing data with your supply chain partners, there are agreements/arragements in place <i>regarding how your data should be used.</i>"),
			agreeQuestions("p5-2", "When sharing data with your supply chain partners, there are agreements/arragements in place <i>regarding who can have access to your data.</i>"),
			agreeQuestions("p5-3", "When sharing data with your supply chain partners, there are agreements/arragements in place <i>ensuring that your business retains ownership of your data.</i>"),
			agreeQuestions("p5-4", "Your business has equal say when setting up agreements/arragements with your supply chain partners on data sharing.", false, true),
			agreeQuestions("p5-5", "You trust that the current data sharing agreement/arrangement with your supply chain partners can ensure the integrity and security of your data.", false, true),
			agreeQuestions("p5-6", "You receive feedback information on your product quality from your supply chain partners (e.g., processers, packers, marketers, exporters). ")])
		].flat()
	}, {
		"navigationTitle": "Demographics",
		"elements": [
			description("Section 6: Demographics", "These final questions will be used to describe the broader sample of agribusinesses that complete the assessment. Your individual responses will be kept private and confidential."),
			{
				"type": "checkbox",
				"name": "Industry sector",
				"title": "What is the main sector of your business? (select all that apply)",
				"choices": arrayToValue([ "Almond", "Beef Cattle", "Honey", "Pork", "Sheep Meat", "Goat"]),
				"showOtherItem": true,
				"otherText": "Other (please indicate)",
				"isRequired": isRequired,
			}, {
				"name": "Business size",
				"type": "radiogroup",
				"title": "How many staff are employed in your business? (including yourself)",
				"isRequired": isRequired,
				"choices": arrayToValue(["1-5", "6-10", "11-15", "16-20", "21-30", "over 30"])
			}, {
				"name": "Business income",
				"type": "radiogroup",
				"title": "What is your business' annual gross revenue",
				"isRequired": isRequired,
				"choices": arrayToValue(["$0-$100K", "$100K-$200K", "$200K-$500K", "$500K-$1M", "$1M-$10M", "Greater than $10M", "Prefer not to say"])
			}, {
				"name": "Export",
				"type": "radiogroup",
				"title": "Do you export any of your products?",
				"isRequired": isRequired,
				"choices": arrayToValue(["Yes", "No"])
			}, {
				"name": "Age",
				"type": "radiogroup",
				"title": "What is your age?",
				"isRequired": isRequired,
				"choices": arrayToValue(["18 - 24 years old", "25 - 34 years old", "35 - 44 years old", "45 - 54 years old", "55 - 64 years old", "65+ years old", "Prefer not to say"])
			}, {
				"name": "Gender",
				"type": "radiogroup",
				"title": "What is your gender?",
				"isRequired": isRequired,
				"choices": arrayToValue(["Male", "Female", "Other", "Prefer not to say"])
			}, {
				"name": "Education",
				"type": "radiogroup",
				"title": "What is your highest level of education?",
				"isRequired": isRequired,
				"choices": arrayToValue(["Did not complete Year 12", "Completed Year 12", "Post-secondary qualification - agriculture", "Post-secondary qualification - other", "Undergraduate degree - agriculture", "Undergraduate degree - other", "Postgraduate degree - agriculture", "Postgraduate degree – other", "Prefer not to say"])
			}, {
				"name": "Your State",
				"type": "radiogroup",
				"title": "What is your State",
				"isRequired": isRequired,
				"choices": arrayToValue(["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"])
			}
		]
	}],
};
