export const feedback = {
    "Value & strategy": [
        "An emerging business currently perceives a low value of, and place low priority on, implementing digital traceability system(s).",
        "A transitional business is starting to value the application of digital traceability. However, they are yet to develop a clear action plan for adopting and utilising digital traceability.",
        "A competitive business sees the value in digital traceability, and has developed and enacted a strategic plan for adopting and utilising digital traceability system(s).",
        "A transformative business places a high priority and value on digital traceability, and has a clearly-defined path towards a digital future where utilising digital technologies is key for implementing traceability.",
    ],
    "Technology": [
        "An emerging business’s communication infrastructure does not support their data and digital technology needs. There is limited use of digital technologies and technical support is poor. Their business has limited understanding of new digital technologies on the market.",
        "A transitional business’s communication infrastructure barely supports their data and digital technology needs. Their business is starting to deploy digital technologies. The business has some understanding of new digital technologies on the market but finds it difficult to choose new technologies that meet their needs.",
        "A competitive business’s communication infrastructure adequately supports their data and digital technology needs. Their business deploys digital technologies and receives satisfactory technical support. Their business generally understands, and finds it reasonably easy to choose, new digital technologies that meet their needs.",
        "A transformative business’s communication infrastructure fully supports their data and digital technology needs. Digital technologies are effective and fully utilised in the business, coupled with strong technical support. Their business completely understands, and finds it easy to choose, new digital technologies that meet their needs."
    ],
    "Data collection": [
        "An emerging business currently collects limited data, which is difficult to access in-business and by their supply chain partners. Data from multiple sources cannot be integrated and analysed for traceability.",
        "A transitional business collects some relevant data, but the data cannot be easily accessed in-business and by their supply chain partners. Data from multiple sources cannot be easily integrated and analysed for traceability.",
        "A competitive business collects a lot of relevant data and most of the data can be easily accessed in-business and by their supply chain partners. There are challenges when integrating data from multiple sources.",
        "A transformative business collects all relevant data and it is of high quality. All data can be easily accessed in-business and by their supply chain partners. Data from multiple sources can be readily integrated and analysed for traceability."
    ],
    "Capability": [
        "An emerging business currently lacks the knowledge, skills, and abilities to use digital technologies and manage data. Their business has limited understanding of where to source external expertise and training opportunities are not taken up.",
        "A transitional business has limited knowledge, skills, and abilities to use digital technologies and manage data. Their business finds it hard to seek source external expertise and does not actively upskill staff.",
        "A competitive business has sound knowledge, skills, and abilities to use digital technologies and manage data. Their business knows where to source expertise and makes an effort to upskill staff.",
        "A transformative business has comprehensive knowledge, skills, and abilities to fully utilise digital technologies and manage data. Their business knows where to source expertise and prioritises upskilling staff."
    ],
    "Data sharing": [
        "An emerging business’ data sharing with their supply chain partners is not adequately governed by agreements regarding the appropriate use of data.",
        "A transitional business’ data sharing with their supply chain partners is not adequately governed by agreements regarding the appropriate use of data.",
        "A competitive business’ data sharing with their supply chain partners is largely governed by agreements regarding the appropriate use of data.",
        "A transformative business’ data sharing with their supply chain partners is fully governed by agreements regarding the appropriate use of data."
    ]
}